h1 {
  font-size: 1.8em;
  font-weight: 500;
  line-height: 1.2em;
}

h2 {
  margin-bottom: 1em;
  font-size: 1.6em;
  line-height: 1.6em;
}

h3 {
  margin-bottom: 1em;
  font-size: 1.4em;
  line-height: 1.4em;
}

h5 {
  margin-bottom: 1em;
  font-size: 1.2em;
  line-height: 1.2em;
}

p,
q,
ul {
  margin-bottom: 1em;
}
