.swiper-container {
  padding: 0 3em;

  .swiper-slide {
    text-align: center;
  }

  .swiper-button-prev {
    background: url('data:image/svg+xml;utf8,<svg fill="#000000" height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') no-repeat;
  }

  .swiper-button-next {
    background: url('data:image/svg+xml;utf8,<svg fill="#000000" height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') no-repeat;
  }
}

.members-container {
  @include touch {
    display: none;
  }
}

.members-mobile-container {
  @include desktop {
    display: none;
  }
}
